import React, { useState, useEffect } from 'react';
import Hero from './components/Hero';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import PageLoader from './components/PageLoader';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check if all images are loaded
    const images = document.querySelectorAll('img');
    const totalImages = images.length;
    let loadedImages = 0;

    function imageLoaded() {
      loadedImages++;
      if (loadedImages === totalImages) {
        setIsLoading(false);
      }
    }

    // If there are no images, remove loading screen
    if (totalImages === 0) {
      setIsLoading(false);
      return;
    }

    // Add load event listener to each image
    images.forEach(img => {
      if (img.complete) {
        imageLoaded();
      } else {
        img.addEventListener('load', imageLoaded);
        img.addEventListener('error', imageLoaded); // Count errors as loaded to prevent infinite loading
      }
    });

    // Cleanup
    return () => {
      images.forEach(img => {
        img.removeEventListener('load', imageLoaded);
        img.removeEventListener('error', imageLoaded);
      });
    };
  }, []);

  return (
    <>
      <PageLoader isLoading={isLoading} />
      <div className={`bg-gray-900 min-h-screen ${isLoading ? 'hidden' : ''}`}>
        <Navbar />
        <main>
          <Hero />
          <About />
          <Projects />
          <Skills />
          <Blog />
          <Contact />
        </main>
      </div>
    </>
  );
}

export default App; 