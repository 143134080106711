import React, { useState, useEffect } from 'react';
import { motion, useMotionTemplate, useMotionValue } from 'framer-motion';
import { FaGithub, FaArrowRight } from 'react-icons/fa';

const ProjectCard = ({ project, index }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [blurredLoaded, setBlurredLoaded] = useState(false);
   
  // Mouse position for 3D effect
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  // Preload both blurred and full images
  useEffect(() => {
    // Load blurred thumbnail first
    const blurredImg = new Image();
    blurredImg.src = `${project.image.replace('.jpg', '-blur.jpg')}`;
    blurredImg.onload = () => {
      setBlurredLoaded(true);
    };

    // Then load full resolution image
    const fullImg = new Image();
    fullImg.src = project.image;
    fullImg.onload = () => {
      setImageLoaded(true);
    };
  }, [project.image]);

  const handleMouseMove = (e) => {
    const { currentTarget, clientX, clientY } = e;
    const { left, top, width, height } = currentTarget.getBoundingClientRect();
    
    mouseX.set((clientX - left - width / 2) / 25);
    mouseY.set((clientY - top - height / 2) / 25);
  };

  const rotateX = useMotionTemplate`rotateX(${mouseY}deg)`;
  const rotateY = useMotionTemplate`rotateY(${mouseX}deg)`;

  return (
    <motion.a
      href={project.link}
      target="_blank"
      rel="noopener noreferrer"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      viewport={{ once: true }}
      className="block transform-gpu"
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        mouseX.set(0);
        mouseY.set(0);
      }}
      style={{
        perspective: "1000px",
      }}
    >
      <motion.div
        style={{
          rotateX,
          rotateY,
        }}
        className="glass-effect rounded-xl overflow-hidden hover:border-gray-500/50 transition-all duration-300 h-full"
      >
        <motion.div
          className="h-48 relative overflow-hidden"
          animate={isHovered ? { scale: 1.05 } : { scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          {!blurredLoaded && (
            <motion.div 
              className="absolute inset-0 bg-gray-800 flex items-center justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <div className="w-8 h-8 border-2 border-purple-500 border-t-transparent rounded-full animate-spin"></div>
            </motion.div>
          )}
          {blurredLoaded && (
            <motion.div 
              className="absolute inset-0 bg-cover bg-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              style={{
                backgroundImage: `url(${project.image.replace('.jpg', '-blur.jpg')})`,
                filter: 'brightness(0.7) blur(10px)',
              }}
            />
          )}
          <motion.div 
            className="absolute inset-0 bg-cover bg-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: imageLoaded ? 1 : 0 }}
            transition={{ duration: 0.5 }}
            style={{
              backgroundImage: `url(${project.image})`,
              filter: 'brightness(0.7)',
            }}
          />
          <motion.div
            className="absolute inset-0 bg-black/40"
            initial={false}
            animate={{ opacity: isHovered ? 0.6 : 0.4 }}
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/20 to-transparent" />
          <h3 className="text-2xl font-bold text-white text-center relative z-10 h-full flex items-center justify-center p-6">{project.title}</h3>
        </motion.div>
        <div className="p-6">
          <p className="text-gray-300 mb-4">{project.description}</p>
          <div className="flex flex-wrap gap-2">
            {project.tags.map((tag) => (
              <motion.span
                key={tag}
                className="px-3 py-1 rounded-full text-sm glass-effect text-gray-300"
                whileHover={{ scale: 1.05, color: "#fff" }}
              >
                {tag}
              </motion.span>
            ))}
          </div>
          <motion.div
            className="mt-4 text-purple-400 text-sm"
            initial={false}
            animate={{ opacity: isHovered ? 1 : 0 }}
          >
            Click to view project →
          </motion.div>
        </div>
      </motion.div>
    </motion.a>
  );
};

const Projects = () => {
  const projects = [
    {
      title: 'ContainerZen',
      description: 'A modern container management solution built with JavaScript. Helps in managing and orchestrating containers efficiently.',
      tags: ['JavaScript', 'Docker', 'Container Management'],
      image: '/images/projects/container-zen.jpg',
      link: 'https://github.com/Suryah07/ContainerZen'
    },
    {
      title: 'Stealth-Ruler',
      description: 'A command and control tool designed to operate over the Tor network, developed in Python3. Focuses on secure and anonymous communication.',
      tags: ['Python', 'Tor Network', 'Security', 'Networking'],
      image: '/images/projects/stealth-ruler.jpg',
      link: 'https://github.com/Suryah07/Stealth-Ruler'
    },
    {
      title: 'Apartment Management System',
      description: 'A comprehensive DBMS project for managing apartment complexes, including resident information, maintenance, and billing systems.',
      tags: ['JavaScript', 'MySQL', 'Database Design', 'Web Development'],
      image: '/images/projects/apartment-management.jpg',
      link: 'https://github.com/Suryah07/Appartment-management-system-dbms'
    },
    {
      title: 'Stroke Patient Rehabilitation Website',
      description: 'A specialized website designed to assist in the rehabilitation process of stroke patients, providing monitoring tools and progress tracking capabilities.',
      tags: ['JavaScript', 'Healthcare', 'UI/UX', 'Web Development'],
      image: '/images/projects/rehabilitation.jpg',
      link: 'https://github.com/Suryah07/Website-for-rehabilation-device-of-stoke-patient'
    }
  ];

  return (
    <section id="projects" className="py-20 bg-gray-900 relative">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900/95 to-gray-900/90"></div>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">My Projects</h2>
          <div className="w-20 h-1 bg-gradient-to-r from-purple-500 to-blue-500 mx-auto"></div>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mb-12">
          {projects.map((project, index) => (
            <ProjectCard key={project.title} project={project} index={index} />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-center"
        >
          <motion.a
            href="https://github.com/Suryah07"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-3 text-gray-300 hover:text-purple-400 transition-colors glass-effect px-6 py-3 rounded-full"
            whileHover={{ scale: 1.05, y: -2 }}
          >
            <FaGithub className="text-2xl" />
            <span className="text-lg">View more projects on GitHub</span>
            <FaArrowRight />
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default Projects; 