import React from 'react';
import { motion } from 'framer-motion';
import { FaServer, FaExternalLinkAlt, FaCode, FaDatabase } from 'react-icons/fa';

const Blog = () => {
  return (
    <section id="blog" className="py-20 bg-gray-900 relative overflow-hidden">
      {/* Modern animated background */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900/95 to-gray-900/90"></div>
      <div className="absolute top-0 right-0 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl animate-pulse"></div>
      <div className="absolute bottom-0 left-0 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl animate-pulse" style={{ animationDelay: '1s' }}></div>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">Personal Lab & Blog</h2>
          <div className="w-20 h-1 bg-gradient-to-r from-purple-500 to-blue-500 mx-auto"></div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="max-w-4xl mx-auto"
        >
          <div className="glass-effect rounded-2xl p-8 border border-gray-800/50 shadow-lg backdrop-blur-sm">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className="glass-effect rounded-xl p-6 border border-purple-500/10 hover:border-purple-500/30 transition-all duration-300"
              >
                <FaServer className="text-3xl text-purple-400 mb-4" />
                <h3 className="text-lg font-semibold text-white mb-2">Personal Server</h3>
                <p className="text-gray-400 text-sm">Self-hosted environment for learning and experimentation</p>
              </motion.div>
              
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className="glass-effect rounded-xl p-6 border border-blue-500/10 hover:border-blue-500/30 transition-all duration-300"
              >
                <FaCode className="text-3xl text-blue-400 mb-4" />
                <h3 className="text-lg font-semibold text-white mb-2">Tech Stack</h3>
                <p className="text-gray-400 text-sm">Working with Docker, Networks, and Modern DevOps tools</p>
              </motion.div>
              
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className="glass-effect rounded-xl p-6 border border-indigo-500/10 hover:border-indigo-500/30 transition-all duration-300"
              >
                <FaDatabase className="text-3xl text-indigo-400 mb-4" />
                <h3 className="text-lg font-semibold text-white mb-2">Tech Blog</h3>
                <p className="text-gray-400 text-sm">Sharing learnings and implementation experiences</p>
              </motion.div>
            </div>

            <div className="text-center space-y-4">
              <p className="text-gray-300">
                I run my own personal server where I implement and experiment with various technologies.
                This hands-on approach helps me understand and work with different tools and services in a real environment.
              </p>
              <p className="text-gray-300">
                From setting up Docker containers to managing network configurations and exploring DevOps practices,
                I document my journey of learning and problem-solving through practical implementations on my blog.
              </p>
            </div>

            <motion.div 
              className="mt-8 text-center"
              whileHover={{ scale: 1.02 }}
            >
              <a 
                href="https://blog.suryah.xyz/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 px-6 py-3 rounded-full bg-gradient-to-r from-purple-500 to-blue-500 text-white font-medium hover:from-purple-600 hover:to-blue-600 transition-all duration-300 shadow-lg hover:shadow-purple-500/25"
              >
                Visit My Blog <FaExternalLinkAlt className="text-sm" />
              </a>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Blog; 