import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const Hero = () => {
  const containerRef = useRef(null);
  
  // Mouse follow effect
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });
  
  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const { innerWidth, innerHeight } = window;
      const x = (clientX / innerWidth - 0.5) * 20;
      const y = (clientY / innerHeight - 0.5) * 20;
      setMousePosition({ x, y });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <section 
      ref={containerRef}
      id="home" 
      className="min-h-screen flex items-center justify-center bg-gray-900 relative overflow-hidden"
    >
      {/* Animated background gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-purple-900/20 via-gray-900 to-blue-900/20 animate-gradient"></div>
      
      {/* Interactive background elements */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-2 h-2 bg-purple-500/30 rounded-full"
            animate={{
              x: [
                Math.random() * window.innerWidth,
                Math.random() * window.innerWidth,
              ],
              y: [
                Math.random() * window.innerHeight,
                Math.random() * window.innerHeight,
              ],
              scale: [1, 1.5, 1],
            }}
            transition={{
              duration: Math.random() * 10 + 10,
              repeat: Infinity,
              ease: "linear",
            }}
          />
        ))}
      </div>

      {/* Animated circles */}
      <motion.div
        className="absolute top-20 left-20 w-72 h-72 bg-purple-500/10 rounded-full filter blur-3xl"
        animate={{
          x: mousePosition.x * 2,
          y: mousePosition.y * 2,
        }}
        transition={{ type: "spring", stiffness: 50 }}
      />
      <motion.div
        className="absolute bottom-20 right-20 w-72 h-72 bg-blue-500/10 rounded-full filter blur-3xl"
        animate={{
          x: mousePosition.x * -2,
          y: mousePosition.y * -2,
        }}
        transition={{ type: "spring", stiffness: 50 }}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 text-center relative z-10">
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
        >
          <motion.h1 
            className="text-5xl sm:text-7xl font-bold mb-4"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 200 }}
          >
            Hi, I'm <span className="gradient-text">Suryah</span>
          </motion.h1>
          <h2 className="text-xl sm:text-2xl text-gray-300 mb-8 font-light">
            <motion.span>
              Data Engineer
            </motion.span>
            {" | "}
            <motion.span>
              Backend Developer
            </motion.span>
            {" | "}
            <motion.span>
              Tech Explorer
            </motion.span>
          </h2>
          <motion.p 
            className="text-lg text-gray-400 mb-12 max-w-2xl mx-auto"
          >
            I'm passionate about building robust data pipelines and scalable backend systems.
            Exploring new technologies through hands-on implementation on my personal server.
          </motion.p>
          <div className="flex justify-center space-x-6">
            <motion.button
              whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(147, 51, 234, 0.3)" }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 rounded-lg glass-effect text-purple-300 hover:text-purple-200 hover:border-purple-500/50 transition-all duration-300"
              onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
            >
              Get in Touch
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(147, 51, 234, 0.3)" }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 text-white hover:from-purple-500 hover:to-blue-500 transition-all duration-300"
              onClick={() => document.getElementById('projects').scrollIntoView({ behavior: 'smooth' })}
            >
              View Work
            </motion.button>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero; 