import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  const experiences = [
    {
      title: "Associate Data Engineer",
      company: "Micron Technology",
      period: "Jul 2024 - Present",
      description: "Working as an Associate Data Engineer at Micron Technology, Hyderabad"
    },
    {
      title: "Data Engineering Intern",
      company: "Micron Technology",
      period: "Jan 2024 - Jul 2024",
      description: "Worked as a Data Engineering Intern focusing on data pipeline development and optimization"
    }
  ];

  const education = {
    degree: "B.Tech Information Technology",
    institution: "PSG College of Technology",
    location: "Coimbatore",
    period: "2020 - 2024"
  };

  return (
    <section id="about" className="py-20 bg-gray-900/50 relative">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900/95 to-gray-900"></div>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">About Me</h2>
          <div className="w-20 h-1 bg-gradient-to-r from-purple-500 to-blue-500 mx-auto"></div>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <div className="text-center md:text-left">
              <p className="text-lg text-gray-300">
                Hello! I'm Suryah, a passionate Data Engineer with expertise in backend development and networking. 
                I've completed my B.Tech in Information Technology from PSG College of Technology, Coimbatore.
              </p>
              <p className="text-lg text-gray-300 mt-4">
                My journey in tech is driven by building robust data pipelines and scalable backend systems. 
                I love working with modern technologies and frameworks to create efficient data solutions.
              </p>
            </div>

            {/* Experience Section */}
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-white text-center md:text-left">Experience</h3>
              {experiences.map((exp, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -10 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="glass-effect p-4 rounded-lg text-center md:text-left"
                >
                  <h4 className="gradient-text font-semibold">{exp.title}</h4>
                  <p className="text-gray-300">{exp.company}</p>
                  <p className="text-gray-400 text-sm">{exp.period}</p>
                  <p className="text-gray-300 mt-2">{exp.description}</p>
                </motion.div>
              ))}
            </div>

            {/* Education Section */}
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-white text-center md:text-left">Education</h3>
              <motion.div
                initial={{ opacity: 0, x: -10 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3 }}
                viewport={{ once: true }}
                className="glass-effect p-4 rounded-lg text-center md:text-left"
              >
                <h4 className="gradient-text font-semibold">{education.degree}</h4>
                <p className="text-gray-300">{education.institution}</p>
                <p className="text-gray-400 text-sm">{education.period}</p>
                <p className="text-gray-300 mt-2">{education.location}</p>
              </motion.div>
            </div>

            <div className="flex flex-wrap gap-4 justify-center md:justify-start">
              <div className="glass-effect px-4 py-2 rounded-lg">
                <span className="gradient-text font-semibold">Backend</span> <span className="text-gray-300">Engineer</span>
              </div>
              <div className="glass-effect px-4 py-2 rounded-lg">
                <span className="gradient-text font-semibold">Data</span> <span className="text-gray-300">Engineer</span>
              </div>
              <div className="glass-effect px-4 py-2 rounded-lg">
                <span className="text-gray-300">Computer</span> <span className="gradient-text font-semibold">Networks</span>
              </div>
              <div className="glass-effect px-4 py-2 rounded-lg">
                <span className="gradient-text font-semibold">IOT</span> <span className="text-gray-300">Developer</span>
              </div>
              <div className="glass-effect px-4 py-2 rounded-lg">
                <span className="gradient-text font-semibold">Docker</span>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            viewport={{ once: true }}
            className="relative order-first md:order-last"
          >
            <div className="aspect-w-4 aspect-h-3 rounded-2xl overflow-hidden glass-effect p-2">
              <img 
                src="/images/profile.png" 
                alt="Suryah at Jatayu Earth's Center"
                className="w-full h-full object-cover rounded-xl brightness-75"
                style={{ objectPosition: "center 40%" }}
              />
              {/* Dark overlay */}
              <div className="absolute inset-0 bg-black/20"></div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About; 