import React from 'react';
import { motion } from 'framer-motion';

const PageLoader = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <motion.div
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900"
    >
      <div className="relative">
        {/* Outer circle */}
        <motion.div
          animate={{
            rotate: 360,
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "linear"
          }}
          className="w-16 h-16 border-4 border-purple-500/30 rounded-full"
        />
        {/* Inner circle */}
        <motion.div
          animate={{
            rotate: -360,
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "linear"
          }}
          className="absolute inset-0 flex items-center justify-center"
        >
          <div className="w-10 h-10 border-4 border-t-purple-500 border-r-purple-500 border-b-transparent border-l-transparent rounded-full" />
        </motion.div>
        {/* Loading text */}
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="absolute -bottom-8 left-1/2 -translate-x-1/2 text-purple-500 font-medium"
        >
          Loading...
        </motion.div>
      </div>
    </motion.div>
  );
};

export default PageLoader; 