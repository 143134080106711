import React from 'react';
import { motion } from 'framer-motion';

const SkillCard = ({ skillGroup, groupIndex }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: groupIndex * 0.1 }}
      viewport={{ once: true }}
      className="glass-effect rounded-xl p-6 hover:border-gray-500/50 transition-all duration-300 transform-gpu"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      whileHover={{ y: -5 }}
    >
      <motion.div
        className={`h-12 bg-gradient-to-r ${skillGroup.gradient} rounded-lg mb-6 flex items-center justify-center relative overflow-hidden`}
        animate={isHovered ? { scale: 1.02 } : { scale: 1 }}
      >
        <motion.div
          className="absolute inset-0 bg-white"
          initial={{ x: '-100%' }}
          animate={isHovered ? { x: '100%' } : { x: '-100%' }}
          transition={{ duration: 0.5 }}
          style={{ opacity: 0.1 }}
        />
        <h3 className="text-xl font-semibold text-white relative z-10">{skillGroup.category}</h3>
      </motion.div>

      <div className="grid grid-cols-2 gap-3">
        {skillGroup.items.map((skill, index) => (
          <motion.div
            key={skill}
            initial={{ opacity: 0, x: -10 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
            viewport={{ once: true }}
            className="relative"
          >
            <motion.div
              className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-800/30 transition-colors"
              whileHover={{ x: 5 }}
            >
              <motion.div
                className={`w-2 h-2 rounded-full bg-gradient-to-r ${skillGroup.gradient}`}
                animate={isHovered ? { scale: [1, 1.5, 1] } : { scale: 1 }}
                transition={{ duration: 0.5, repeat: isHovered ? Infinity : 0 }}
              />
              <span className="text-gray-300">{skill}</span>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

const Skills = () => {
  const skills = [
    {
      category: 'Languages & Frameworks',
      items: ['Python', 'JavaScript', 'React', 'Node.js', 'Express', 'Flask'],
      gradient: 'from-purple-500 to-pink-500',
    },
    {
      category: 'Tools & Technologies',
      items: ['Git', 'Docker', 'AWS', 'MongoDB', 'PostgreSQL', 'Linux'],
      gradient: 'from-blue-500 to-cyan-500',
    },
    {
      category: 'Cloud & Networks',
      items: ['AWS Services', 'Docker', 'Network Security', 'TCP/IP', 'Kubernetes', 'DevOps'],
      gradient: 'from-green-500 to-emerald-500',
    },
  ];

  return (
    <section id="skills" className="py-20 bg-gray-900 relative overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900/95 to-gray-900/90"></div>
      <div className="absolute top-0 left-0 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl"></div>
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl"></div>

      {/* Floating particles */}
      {[...Array(15)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-1 h-1 bg-purple-500/20 rounded-full"
          animate={{
            x: [
              Math.random() * window.innerWidth,
              Math.random() * window.innerWidth,
            ],
            y: [
              Math.random() * window.innerHeight,
              Math.random() * window.innerHeight,
            ],
          }}
          transition={{
            duration: Math.random() * 10 + 10,
            repeat: Infinity,
            ease: "linear",
          }}
        />
      ))}

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">Skills & Expertise</h2>
          <div className="w-20 h-1 bg-gradient-to-r from-purple-500 to-blue-500 mx-auto"></div>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {skills.map((skillGroup, groupIndex) => (
            <SkillCard key={skillGroup.category} skillGroup={skillGroup} groupIndex={groupIndex} />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          viewport={{ once: true }}
          className="mt-12 text-center"
        >
          <p className="text-gray-400 max-w-2xl mx-auto">
            Continuously exploring new technologies and frameworks to stay at the forefront of tech innovation.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default Skills; 