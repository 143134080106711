import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { put } from '@vercel/blob';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: null
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, success: false, error: null });

    try {
      // Create a timestamp for unique file name
      const timestamp = new Date().toISOString();
      const fileName = `contact-messages/${timestamp}-${formData.name.toLowerCase().replace(/\s+/g, '-')}.json`;

      // Convert form data to JSON string
      const messageData = JSON.stringify({
        ...formData,
        timestamp,
        status: 'unread'
      });

      // Create a Blob from the JSON string
      const blob = new Blob([messageData], { type: 'application/json' });

      // Upload to Vercel Blob
      await put(fileName, blob, {
        access: 'public',
        // addRandomSuffix: false // Keep the filename as is
        token: "vercel_blob_rw_UqcSqsYVPqZNLKrw_B83cxdZcy82GHEX5akkeLMHJLOy4CM",
      });

      setStatus({ loading: false, success: true, error: null });
      setFormData({ name: '', email: '', message: '' }); // Reset form
      
      // Reset success message after 5 seconds
      setTimeout(() => {
        setStatus(prev => ({ ...prev, success: false }));
      }, 5000);
    } catch (error) {
      console.error('Error saving message:', error);
      setStatus({ loading: false, success: false, error: 'Failed to send message. Please try again.' });
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const contactInfo = [
    {
      icon: <FaEnvelope className="text-purple-400" size={20} />,
      label: 'Email',
      value: 'suryahdhamodharan@gmail.com',
      link: 'mailto:suryahdhamodharan@gmail.com'
    },
    {
      icon: <FaPhone className="text-purple-400" size={20} />,
      label: 'Phone',
      value: '+91 9xxxxxxxxx',
      link: undefined
    },
    {
      icon: <FaMapMarkerAlt className="text-purple-400" size={20} />,
      label: 'Location',
      value: 'Coimbatore, Tamil Nadu, India',
      link: 'https://goo.gl/maps/YQVRzJRzUiZFGp8z7'
    }
  ];

  const socialLinks = [
    {
      icon: <FaGithub size={24} />,
      url: 'https://github.com/Suryah07',
      label: 'GitHub'
    },
    {
      icon: <FaLinkedin size={24} />,
      url: 'https://www.linkedin.com/in/suryah-dk/',
      label: 'LinkedIn'
    }
  ];

  return (
    <section id="contact" className="py-20 bg-gray-900 relative">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900/95 to-gray-900/90"></div>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">Get in Touch</h2>
          <div className="w-20 h-1 bg-gradient-to-r from-purple-500 to-blue-500 mx-auto"></div>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <h3 className="text-2xl font-semibold text-white mb-6">Contact Information</h3>
            
            <div className="space-y-6 mb-8">
              {contactInfo.map((info, index) => (
                <motion.a
                  key={info.label}
                  href={info.link}
                  target={info.label === 'Location' ? '_blank' : undefined}
                  rel={info.label === 'Location' ? 'noopener noreferrer' : undefined}
                  initial={{ opacity: 0, x: -10 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="glass-effect p-4 rounded-lg flex items-center space-x-4 hover:border-purple-500/50 transition-all duration-300"
                >
                  {info.icon}
                  <div>
                    <p className="text-gray-400 text-sm">{info.label}</p>
                    <p className="text-gray-200">{info.value}</p>
                  </div>
                </motion.a>
              ))}
            </div>

            <h4 className="text-xl font-semibold text-white mb-4">Connect With Me</h4>
            <div className="flex space-x-4">
              {socialLinks.map((social, index) => (
                <motion.a
                  key={social.label}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  whileHover={{ scale: 1.1 }}
                  className="glass-effect p-3 rounded-lg text-gray-300 hover:text-purple-400 hover:border-purple-500/50 transition-all duration-300"
                >
                  {social.icon}
                </motion.a>
              ))}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-300">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  disabled={status.loading}
                  className="mt-1 block w-full rounded-lg glass-effect border-gray-600 bg-gray-800/50 text-gray-100 focus:border-purple-500 focus:ring-purple-500 disabled:opacity-50"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled={status.loading}
                  className="mt-1 block w-full rounded-lg glass-effect border-gray-600 bg-gray-800/50 text-gray-100 focus:border-purple-500 focus:ring-purple-500 disabled:opacity-50"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-300">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  disabled={status.loading}
                  rows={4}
                  className="mt-1 block w-full rounded-lg glass-effect border-gray-600 bg-gray-800/50 text-gray-100 focus:border-purple-500 focus:ring-purple-500 disabled:opacity-50"
                />
              </div>

              {status.error && (
                <div className="text-red-500 text-sm">
                  {status.error}
                </div>
              )}

              {status.success && (
                <div className="text-green-500 text-sm">
                  Message sent successfully! I'll get back to you soon.
                </div>
              )}

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="submit"
                disabled={status.loading}
                className={`w-full px-8 py-3 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 text-white hover:from-purple-500 hover:to-blue-500 transition-all duration-300 disabled:opacity-50 ${status.loading ? 'cursor-not-allowed' : ''}`}
              >
                {status.loading ? 'Sending...' : 'Send Message'}
              </motion.button>
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact; 